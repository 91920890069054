.notification-container {
  font-size: 14px;
  box-sizing: border-box;
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px;
  transition: transform 0.6s ease-in-out;
  animation: toast-in-right 0.7s;
  z-index: 100000;
}

.notification-wrapper {
  background-color: white;
  width: 20%;
  position: relative;
  padding: 5px 16px;
  border-radius: 10px;
  transition: transform 0.6s ease-in-out;
  animation: toast-in-right 0.7s;
  color: white;
  font-weight: 700;
}

.btn-close {
  position: absolute;
  top: 5px;
  right: 5px;
  color: white;
  font-weight: 700;
}

.disappear {
  top: -10000px;
  transition: transform 1s ease-in-out;
}

.top-right {
  top: 12px;
  transition: transform 1s ease-in-out;
}

.bottom-right {
  bottom: 12px;
  right: 12px;
}

.top-center {
  top: 12px;
}

.top-left {
  top: 12px;
  left: 12px;
  transition: transform 0.6s ease-in;
  animation: toast-in-left 0.7s;
}

.bottom-left {
  bottom: 12px;
  left: 12px;
  transition: transform 0.6s ease-in;
  animation: toast-in-left 0.7s;
}
