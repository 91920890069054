.table-wrapper {
  width: 100%;
  margin: 20px 0;
}

.pagination-wrapper {
    display: flex;
    margin-top: 10px;
    padding: 0;

    .item-pagination {
        border: 1px solid #ccc;
    }

    .btn {
        width: 30px;
    }
}