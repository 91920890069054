@import "normalize.css";

:root {
  --primary: #673ab7;
  --black: #000000;
  --text-color: #333;
  --secondary: #8190b0;
  --text-color-secondary: #505458;
  --background-color-side-bar: #f7f8ff;

  --white: #fff;

  --search-border-radius: 7px;

  //default layout
  --default-layout-header-height: 60px;
  --default-layout-width: 1150px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

body {
  line-height: 1.5;
  font-size: 1.6rem;
  text-rendering: optimizeSpeed;
  background-color: var(--primary);
}

button,
input,
[tabindex] {
  outline: none;
  border: none;
  background-color: transparent;
}

a[href] {
  color: var(--text-color);
}

li {
  list-style: none;
}

select {
  appearance: none;
}
