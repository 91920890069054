.profile-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  flex-direction: column;
  align-items: center;

  .img-wrapper {
    width: 100%;
    text-align: center;
  }

  img {
    width: 20%;
    border-radius: 100%;
    border: 1px solid black;
  }

  .basic_profile--wrapper {
    width: 100%;
    text-align: center;
  }

  .btn-wrapper {
    width: 100%;
    text-align: center;
    margin-top: 10px;

    button {
      width: 20%;
      background-color: var(--primary);
      padding: 10px;
      border-radius: 10px;
      color: var(--white);
    }
  }
}
