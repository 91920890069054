.wrapper {
  background-color: var(--primary);
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  .logo-wrapper {
    width: 100%;
    text-align: center;
    margin-top: 200px;
  }

  .form-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
    flex-direction: column;
    align-items: center;
  }

  form {
    width: 25%;

    .input-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: var(--white);
      padding: 0px 10px;
      margin-bottom: 20px;
    }

    input {
      width: 100%;
      background-color: white;
      font-size: 24px;
      padding: 10px 0px;
    }

    .input-item {
      padding-left: 3%;
    }

    input[type="submit"] {
      background-color: var(--white);
      border: 0.5px solid var(--text-color-secondary);
      border-radius: 30px;
    }

    .icon-wrapper {
      font-size: 24px;
    }

    .right-icon-wrapper {
      width: 50px;
    }
    .test {
      width: 100%;
    }

    .left-icon-wrapper {
      width: 20px;
      display: inline-block;
      text-align: center;
    }

    .register-navigation-link-wrapper {
      width: 100%;
      text-align: right;
      margin-bottom: 10px;
      .register-navigation-link {
        color: var(--white);
      }
    }
  }

  .other-login-btn-wrapper {
    width: 25%;
  }

  .block-text {
    margin-top: 20px;
    .text {
      color: var(--white);
    }
  }
}
.row-login-btn {
  justify-content: space-between;
  display: flex;
}
