.activity_content {
  button {
    display: inline-block;
    width: 33%;
    font-size: 54px;
    padding: 10px 0;
    text-align: center;
    color: var(--black);
  }

  .input-wrapper {
    border: 1px solid rgba($color: #000000, $alpha: 0.3);
    margin: 10px 0px;
    padding: 10px;
    border-radius: 30px;
    margin-top: 20px;

    input {
      width: 100%;
      font-size: 20px;
      padding: 0px 10px;
    }
  }

  .btn-call-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;

    button {
      width: 50%;
      background-color: var(--primary);
      border-radius: 30px;
    }

    .cancel_btn {
      background-color: red;
    }
  }
  .input_calling-wrapper {
    margin: 20px 0;
    input {
      width: 100%;
      font-size: 20px;
      padding: 0px 10px;
      text-align: center;
    }
  }

  .status-wrapper {
    width: 100%;
    text-align: center;
    margin: 10px 0;

    span {
      width: 50%;
    }
  }
}
