.input-container {
  padding-bottom: 10px;
  position: relative;
}

.error-msg {
  color: white;
  position: absolute;
  bottom: 5px;
}
