.wrapper {
  display: inline-block;
}

.wrapper:active {
  opacity: 0.5;
}

.button-login-wrapper {
  display: flex;
  align-items: center;
  height: 45px;
  border-radius: var(--search-border-radius);
  width: 150px;
  padding: 0px 10px;

  .separate {
    width: 2;
    height: 97%;
    border-left: 2px solid rgba($color: #000000, $alpha: 0.2);
    margin-left: 10px;
  }

  .title-wrapper {
    width: 100%;
    text-align: center;
    font-weight: 500;
  }
}
