.wrapper {
  background-color: var(--primary);
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  .logo-wrapper {
    width: 100%;
    text-align: center;
    margin-top: 100px;
  }

  .nav-switch {
    display: flex;

    li {
      width: 100%;
      text-align: center;

      button {
        width: 100%;
      }
    }

    .active {
      border-bottom: 1px solid var(--primary);
    }
  }

  .content-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
    flex-direction: column;
    align-items: center;

    .content {
      background-color: var(--white);
      width: 30%;
      height: 500px;
      padding:16px;
    }
  }
}

.container {
  width: 100%;
  display: flex;
}

.content {
  flex: 1;
}
